
  
  .sidebar {
    width: 250px;
    background-color: white;
    /* color: white; */
    padding: 20px;
    border-radius: 4px;
    height: 100vh;
    top: 0;
   
    left: 0;
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
  }
  
  
  .sidebar.open {
    transform: translateX(0);
  }
  
  .sidebar li:hover{
    background: rgb(114, 106, 226);
    border-radius: 9px;
    color: white;
  }
  .sidebar h2 {
    text-align: center;
    font-weight: bolder;
    /* background: linear-gradient(90deg,rgb(152,106,182),rgb(147 82 243));; */
  }
  
  .sidebar ul {
    list-style-type: none;
    /* color: #edf2f7; */
    padding: 0;
  }
  
  .sidebar ul li {
    padding: 10px;
    /* color: #edf2f7; */
    margin-left: 30px;
    cursor: pointer;
    
  }
  
  .hamburger {
    display: none;
    position: fixed;
    top: 15px;
    left: 15px;
    background: none;
    border: none;
    color: #707581;
    font-size: 24px;
    z-index: 1001;
  }
  
  /* Mobile view adjustments */
  @media (max-width: 768px) {
    .sidebar {
      transform: translateX(-100%);
      position: fixed;
    }
    
    .sidebar.open {
      transform: translateX(0);
    }
    
    .hamburger {
      display: block;
    }
  }
  
  .main-content {
    flex: 1;
    padding: 20px;
    background-color: #f5f5f5;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .greeting {
    display: flex;
    align-items: center;
  }
  
  .greeting h1 {
    margin: 0;
    font-size: 24px;
    background: linear-gradient(90deg, rgb(152, 106, 182), rgb(68, 28, 127));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .hi-gif {
    width: 40px;
    height: 40px;
    margin-left: 10px;
  }
  
  .header-icons {
    display: flex;
    align-items: center;
  }
  
  .icon {
    margin-left: 15px;
    font-size: 24px;
    cursor: pointer;
  }
  
  .calendar-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .calendar-popup {
    position: absolute;
    top: 35px;
    right: 0;
    z-index: 1000;
    background: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border-radius: 5px;
    overflow: hidden;
  }
  
  .date-time {
    margin-left: 10px;
    font-size: 14px;
  }
  
  
  /* Media Query for smaller screens */
  @media (max-width: 768px) {
    .header {
      padding: 0px 30px;
    }
  
    .header h1 {
      font-size: 20px; /* Slightly smaller font size for mobile screens */
    }
  
    .hi-gif {
      width: 30px; /* Smaller size for mobile screens */
      margin-left: 5px; /* Reduce margin for mobile screens */
    }
  
    .header-icons .icon {
      margin-left: 5px; /* Further reduce the margin between icons */
      font-size: 20px; /* Slightly smaller icon size for mobile screens */
    }
  }
  
  
  
  
  
  .content {
    margin-top: 20px;
  }
  
  /* Styles for desktop */
  .my-task, .my-progress, .best-teacher {
    background-color: white;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  
  .task-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three columns layout */
    gap: 20px; /* Space between cards */
  }
  
  .task-card {
    padding: 20px;
    border-radius: 10px;
    color: white;
  }
  
  .purple {
    background: linear-gradient(90deg, rgb(152, 106, 182), rgb(147, 82, 243));
  }
  
  .orange {
    background: linear-gradient(90deg, rgb(223, 122, 46), rgb(243, 179, 82));
  }
  
  .green {
    background: linear-gradient(90deg, rgb(43, 140, 16), rgb(78, 210, 111));
  }
  
  .pink {
    background: linear-gradient(90deg, rgb(255, 105, 180), rgb(255, 182, 193));
  }
  
  .blue {
    background: linear-gradient(90deg, rgb(70, 130, 180), rgb(100, 149, 237));
  }
  
  /* Media Query for smaller screens */
  @media (max-width: 768px) {
    .task-cards {
      grid-template-columns: 1fr; /* Single column layout */
    }
  
    .task-card {
      margin-bottom: 20px; /* Space between cards in column layout */
    }
  }
  
  
  
  .bottom-section {
    display: flex;
    justify-content: space-between;
  }
  
  .progress-chart img {
    width: 100%;
  }
  
  .teacher-card {
    margin-bottom: 10px;
    width: 30vw;
  }
  
  .teacher-card h3 {
    margin: 0;
  }
  /* AttendanceCalender.css */
.my-progress {
  margin-top: 10px;
  text-align: center;
}

.my-progress .MuiTableContainer-root {
  margin-top: 20px;
  max-width: 1400px;
  margin: auto;
  width: 100%;
  overflow-x: auto; /* Make the table scrollable on smaller screens */
}

.my-progress .MuiTable-root {
  border-collapse: collapse;
  border: 1px solid #ddd;
  width: 100%;
  min-width: 600px; /* Ensure table has a minimum width for horizontal scrolling */
}

.my-progress .MuiTableCell-root {
  border: 1px solid #ddd;
  background-color: #f2f2f2;
  padding: 8px; /* Adjust padding */
}

.my-progress .MuiTableCell-head {
  background-color: #f2f2f2;
}

.my-progress .MuiTableCell-body {
  background-color: #fff;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .my-progress .MuiTableContainer-root {
    width: 100%;
    margin: 0;
  }

  .my-progress .MuiTableCell-root {
    padding: 4px; /* Reduce padding on smaller screens */
    font-size: 0.75rem; /* Reduce font size on smaller screens */
  }
}

  
  
  
  /* Student profile modal css */
  

  /* .modal-overlay {
=======
  .modals-overlay {
>>>>>>> 0458810cd452d0fa231c4a65f62279e93e4219b5
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modals-content {
    background-color: rgb(8, 16, 102);
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .modals-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #181239;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .modals-header h2 {
    margin: 0;
  } */
  
  .close-button {
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
  }
  
  .modals-body {
    max-height: 400px;
    overflow-y: auto;
  }
  
  /* table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background-color: #1e2479;
  }
  
  tr:hover {
    background-color: #dddde6;
  }
   */

 


  /* assignmentDetails */


  .assignment-details {
    font-family: Arial, sans-serif;
    margin: 20px;
  }
  
  .tabs {
    display: flex;
    border-bottom: 2px solid #ddd;
    margin-bottom: 20px;
  }
  
  .tabs button {
    background: none;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-bottom: 2px solid transparent;
  }
  
  .tabs button.active {
    border-bottom: 2px solid #1964b5;
    color: #007bff;
  }
  
  .tab-content {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .assignment-upload,
  .teacher-checked-assignment {
    display: flex;
    flex-direction: column;
  }
  
  .assignment-upload h2,
  .teacher-checked-assignment h2 {
    margin-bottom: 20px;
  }
  
  .assignment-upload input,
  .teacher-checked-assignment input,
  .teacher-checked-assignment select {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: flex;
    align-items: center;
    width: 500px;
  }
  
  .assignment-upload button,
  .teacher-checked-assignment button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #1964b5;
    color: white;
    border: none;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    width: 300px;
  }
  
  .assignment-upload button:hover,
  .teacher-checked-assignment button:hover {
    background-color: #225e9f;
  }
  
  .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .form-group label {
    margin-right: 10px;
    font-weight: bold;
  }
  
  .form-group select,
  .form-group input[type="file"] {
    flex: 1;
  }
  


  /* assignment card css */

  .assignment-card {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin: 10px;
    background: linear-gradient(90deg, rgb(56 69 204), rgb(8 26 120));
 text-decoration: none;
 color: rgb(250, 250, 252);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 300px;
    height: 170px;
    transition: transform 0.2s;
  }
  
  .assignment-card:hover {
    transform: scale(1.05);
  }
  
  .assignment-card h3 {
    margin-top: 0;
    color: #edf2f7;
  }
  
  .assignment-card h4 {
    margin-bottom: 10px;
    color: #787cc2;
  }
  

  .assignment-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    background-color: #ffff;
  }


  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
    z-index: 1000;
  }
  
  .popup p {
    margin: 0 0 10px;
    font-size: 16px;
  }
  
  .popup button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .popup button:hover {
    background-color: #0056b3;
  }
  
  .subject-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  .subject-card {
    width: 150px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .subject-card img {
    width: 100%;
    height: auto;
    margin: 10px 0;
  }
  
  .no-logo {
    font-size: 14px;
    color: #999;
    margin: 10px 0;
  }
  





  
  