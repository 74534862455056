@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom styles for teacher dashboard */
/* Container styles */
.container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

/* Page padding */
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

/* Heading styles */
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

/* Card styles */
.card {
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.card-content {
  padding: 1.5rem;
}

/* Responsive grid */
.grid {
  display: grid;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

/* Margins and spacing */
.mb-4 {
  margin-bottom: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

/* Text colors */
.text-gray-800 {
  color: #2d3748;
}

.text-gray-600 {
  color: #718096;
}

/* Background color for the entire page */
.bg-gray-100 {
  background-color: #edf2f7;
}
